import React, { useState } from 'react';

export const GradeContext = React.createContext();
export const GradeConsumer = GradeContext.Consumer

function GradeProvider({ children }) {

  const[authorizationStatus, setAuthorizationStatus] = useState(null);
  const[schoolGrade, setSchoolGrade] = useState(null);
  const[saveCheck, setSaveCheck] = useState(null);

  return (
    <GradeContext.Provider
      value={{
        authorizationStatus,
        setAuthorizationStatus,
        saveCheck,
        setSaveCheck,
        schoolGrade,
        setSchoolGrade
      }}
    >
      {children}
    </GradeContext.Provider>
  )
}

export default GradeProvider;
