import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const listPerson = (page, size, name = '', inactives, person_type_id = '') => {
  return axios.get(`${API_URL}/persons?page=${page}&size=${size}&name=${name}&inactives=${inactives}&person_type_id=${person_type_id}`);
}

export const findOnePerson = (id) => {
  return axios.get(`${API_URL}/persons/find-one?id=${id}`, { headers: { 'Content-type': 'application/json' } });
}

export const personById = (personId) => {
  return axios.get(`${API_URL}/persons/person-by-id?id=${personId}`);
}
export const listPersonByPersonType = (page, size, name = '', person_type_id = null, inactives) => {
  return axios.get(`${API_URL}/persons/by-person-type?page=${page}&size=${size}&name=${name}&person_type_id=${person_type_id}&inactives=${inactives}`);
}

export const updateUserStatus = (status, id) => {
  return axios.put(`${API_URL}/users/${id}`, { status }, { headers: { 'Content-type': 'application/json' } });
}

export const resetPassword = (id) => {
  return axios.put(`${API_URL}/users/reset-password/${id}`, { headers: { 'Content-type': 'application/json' } });
}

export const updatePassword = (id, password, newPassword) => {
  return axios.put(`${API_URL}/user/update-password?id=${id}&password=${password}&newPassword=${newPassword}`, { headers: { 'Content-type': 'application/json' } });
}

export const changePassword = (payload) => {
  return axios.post(`${API_URL}/user/change-password`, payload, { headers: { 'Content-type': 'application/json' } });
}

export const changePasswordWithoutToken = (payload) => {
  return axios.post(`${API_URL}/user/change-password-without-token`, payload, { headers: { 'Content-type': 'application/json' } });
}

export const listResponsiblesByPersonId = (personId) => {
  return axios.get(`${API_URL}/persons/${personId}/responsibles`);
}

export const createPersonResponsible = (personResponsibleId, personStudentId, responsible_type) => {
  return axios.post(`${API_URL}/responsibles/${personResponsibleId}/student/${personStudentId}`, { responsible_type });
}

export const recover = (email) => {
  return axios.get(`${API_URL}/recover?email=${email}`);
}

export const validateToken = (token) => {
  return axios.get(`${API_URL}/validade-token?token=${token}`);
}

export const findStudentOccurrences = (student_id, year) => {
  return axios.get(`${API_URL}/student-occurrences/filter?student_id=${student_id}&year=${year}`);
}
