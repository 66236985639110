export const GRADE_TYPE = {
    PROVA: 1,
    TRABALHO: 2,
    ATIVIDADE: 3,
    INTERDISCIPLINAR: 4,
    PROJETO: 5,
    SIMULADO: 6,
    PARTICIPACAO: 7,
    FORMATIVA: 8,
    VERIFICACAO: 9,
    EXTRA: 10,
    RECUPERACAO: 11
}

export const BIMESTER = {
    PRIMEIRO_BIMESTRE: 1,
    SEGUNDO_BIMESTRE: 2,
    TERCEIRO_BIMESTRE: 3,
    QUARTO_BIMESTRE: 4
}

export const SUBJECT = {
    PORTUGUES: 1,
    MATEMATICA: 2,
    HISTORIA: 3,
    GEOGRAFIA: 4,
    CIENCIAS: 5,
    INGLES: 6,
    ESPANHOL: 7,
    QUIMICA: 8,
    FISICA: 9,
    BIOLOGIA: 10,
    FILOSOFIA: 11,
    SOCIOLOGIA: 12,
    ARTE: 13,
    EDUCACAOFISICA: 14,
    DESENHOGEOMETRICO: 15,
    ARTESPLASTICAS: 16,
    ARTESCENICAS: 17,
    MUSICA: 18,
    ALGEBRA: 19,
    GRAMATICA: 20,
    REDACAO: 21,
    LITERATURA: 22,
    LABORATORIO: 23,
    EXPERIENCIA: 24,
    PROJETOS: 25,
    EUOUTRONOS: 26,
    CORPOGESTOSMOVIMENTOS: 27,
    TRACOSCORESONSFORMAS: 28,
    ESCUTAFALAPENSAMENTOS: 29,
    ESPACOTEMPOQUANTIDADE: 30,
    DANCAJUDO: 31,
    PROJETOBILINGUE: 32,
    PROJETOMUSICA: 33,
    PROJETOINFORMATICA:34,
    APRENDIZAGEMSOCIAL: 35,
    JUDO: 36,
    MINDLAB: 37,
    RECREACAO: 38,
    EDUCACAOFINANCEIRA: 39,
    INTERPRETACAO: 40,
    IDENTIDADEEAUTONOMIA: 41,
    LINGUAGEMORALEESCRITA: 42,
    NATUREZAESOCIEDADE: 43,
    MOVIMENTO: 44,
    EMPREENDEDORISMO: 45,
    HABILIDADESDIGITAIS: 46,
    CRIACAOEMOTRICIDADE: 47,
    PROGRAMASOCIOEMOCIONAL: 48,
    ORIENTACAOVOCACIONAL: 49,
    XADREZ: 50,
    ROBOTICA: 51,
    PROJETOPRECIOUSPLASTIC: 52,
    PROJETOBALE: 53,
    PROJETOJUDO: 54,
    PROGRAMADEAPRENDIZAGEMSOCIOEMOCIONALEETICA: 55,
    PROJETOESPACOMAKER: 56,
    PROJETOEDUCFINANCEIRAEMPREENDEDORISMO: 57,
    PROJETODEVIDAI: 58,
    PROJETODEVIDAII: 59,
    PROJETODEVIDAIII: 60,
    HISTORIADAEDUCACAOAMBIENTAL: 61,
    PRINCIPIOSEPRATICASDAEDUAMBIENTAL: 62,
    EDAMBIENTALESUSTENTABILIDADEI: 63,
    EDAMBIENTALESUSTENTABILIDADEII: 64,
    FUNDAMENTOSECOSSISTEMAS: 65,
    EADPLASTICOS: 66,
    ATIVIDADEPRATICAOFICINA: 67,
    RESIDUOSSOLIDOS: 68,
    LEITURAEESCRITACRIATIVA: 69,
    CRIACAODESTARTUPS: 70,
    HISTORIADACOMPROBOTICA3D: 71,
    PROGRAMACAOI: 72,
    PROGRAMACAOII: 73,
    PROGRAMACAOIII: 74,
    DESENHOIMPRESSORA3D: 75,
    EADCOMPUTACAOROBOTICA3D: 76,
    IMPACTONOUSUTEC: 77,
    VISITACAO: 78,
    ATIVIDADEPRATICAOFICINAROBOTICA: 79,
    PROJETOELETIVAS: 80,
    FILOSOFIAPASEE: 81,
    EFBALE: 82,
    EFJUDO: 83,
    VIDA1TD: 84,
    VIDA2TD: 85,
    VIDA3TD: 86,
    EMPREENDEDORISMOTD: 87,
    STARTUPTD: 88,
    LEITURATD: 89,
    PROJETODEVIDA: 90
}

export const SCHOOLGRADE = {
    MATERNAL_I: 1,
    MATERNAL_II: 2,
    JARDIM_I: 3,
    JARDIM_II: 4,
    PRIMEIRO_ANO: 5,
    SEGUNDO_ANO: 6,
    TERCEIRO_ANO: 7,
    QUARTO_ANO: 8,
    QUINTO_ANO: 9,
    SEXTO_ANO: 10,
    SETIMO_ANO: 11,
    OITAVO_ANO: 12,
    NONO_ANO: 13,
    PRIMEIRA_SERIE: 14,
    SEGUNDA_SERIE: 15,
    TERCEIRA_SERIE: 16
}

export const SUBJECTGROUP = {
    EXPERIENCIA : 1,
    PROJETOS : 2,
}

export const RECIPIENT_TYPE = {
    TURMA : 1,
    ALUNO : 2,
    TODOSALUNOS: 3,
    RESPONSAVEL: 4,
    TODOSRESPONSAVEIS : 5,
    PROFESSOR : 6,
    TODOSPROFESSORES : 7,
    COLABORADORES : 8,
    TODOS : 9,
    COORDENADOR: 10,
    SECRETARIA: 11
}

export const PERSON_TYPE = {
    ALUNO: 1,
    RESPONSAVEL: 2,
    PROFESSOR: 3,
    COORDENADOR: 4,
    DIRETOR: 5,
    ADMINISTRADOR: 6
}

export const RESPONSIBLE_TYPE = {
    FINANCEIRO: 1,
    PEDAGOGICO: 2
}

export const GENDER_TYPE = {
  MASCULINO: 1,
  FEMININO: 2,
  OUTRO: 3,
  NÃO_DECLARADO: 4
}

export const DIARY_TYPE = {
    MESSAGE : 1,
    REPLY : 2
}

export const SCHOOL_LEVEL = {
  ENSINO_INFANTIL: 1,
  ENSINO_FUNDAMENTAL_I: 2,
  ENSINO_FUNDAMENTAL_II: 3,
  ENSINO_MEDIO: 4,
}





