import React from "react"
import * as Icon from "react-feather"

const navConfigAdmin = [
  {
    type: "groupHeader",
    groupTitle: "ADMINISTRADOR"
  },
  {
    id: "person_manager",
    title: "Gerenciar Pessoas",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/gerenciar-pessoas",
  },
  {
    id: "advises_and_messages",
    title: "Avisos e Mensagens",
    type: "item",
    icon: <Icon.Calendar size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/admin/avisos",
  },
  {
    id: "roll_manager",
    title: "Gerenciar Diários",
    type: "item",
    icon: <Icon.FileText size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/admin/diarios"
  },
  {
    id: "class-manage",
    title: "Gerenciar Turmas",
    type: "collapse",
    badge: "success",
    // badgeText: "3",
    icon: <Icon.Award size={20} />,
    children: [
      {
        id: "class_register",
        title: "Cadastrar Turma",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/admin/cadastrar-turma"
      },
      {
        id: "student_class",
        title: "Turma-Aluno",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/gerenciar-turmas"
      },
      {
        id: "class_subject",
        title: "Turma-Disciplinas",
        type: "item",
        icon: <Icon.Circle size={12} />,
        permissions: ["admin", "editor"],
        navLink: "/admin/disciplina-turma"
      },
    ]
  },
  {
    id: "subjects",
    title: "Disciplinas",
    type: "item",
    icon: <Icon.Book size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/disciplinas",
  },
]

export default navConfigAdmin
