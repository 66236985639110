import { history } from "../../../history"
import axios from "axios"
import { setSession } from '../../../utils/authUtils'
import { Cookies } from 'react-cookie';
const API_URL = process.env.REACT_APP_API_URL;

// import { config } from "../../../authServices/firebase/firebaseConfig"

// Init firebase if not already initialized

// const initAuth0 = new auth0.WebAuth(configAuth)

export const submitLoginWithFireBase = (email, password, remember) => {
  // let firebaseAuth = firebase.auth()
  // return dispatch => {
  //   let userEmail = null,
  //     loggedIn = false
  //   firebaseAuth
  //     .signInWithEmailAndPassword(email, password)
  //     .then(result => {
  //       firebaseAuth.onAuthStateChanged(user => {
  //         result.user.updateProfile({
  //           displayName: "Admin"
  //         })
  //         let name = result.user.displayName
  //         if (user) {
  //           userEmail = user.email
  //           loggedIn = true
  //           dispatch({
  //             type: "LOGIN_WITH_EMAIL",
  //             payload: {
  //               email: userEmail,
  //               name,
  //               isSignedIn: loggedIn,
  //               loggedInWith: "firebase"
  //             }
  //           })
  //         }
  //         if (user && remember) {
  //           firebase
  //             .auth()
  //             .setPersistence(firebase.auth.Auth.Persistence.SESSION)
  //             .then(() => {
  //               dispatch({
  //                 type: "LOGIN_WITH_EMAIL",
  //                 payload: {
  //                   email: userEmail,
  //                   name,
  //                   isSignedIn: loggedIn,
  //                   remember: true,
  //                   loggedInWith: "firebase"
  //                 }
  //               })
  //             })
  //         }
  //         history.push("/")
  //       })
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  // }
}

export const loginWithFB = () => {
  // return dispatch => {
  //   let provider = new firebase.auth.FacebookAuthProvider()
  //   provider.setCustomParameters({
  //     display: "popup"
  //   })
  //   firebaseAuth
  //     .signInWithPopup(provider)
  //     .then(result => {
  //       // This gives you a Facebook Access Token. You can use it to access the Facebook API.
  //       let token = result.credential.accessToken,
  //         // The signed-in user info.
  //         user = result.user.email
  //       dispatch({
  //         type: "LOGIN_WITH_FB",
  //         payload: {
  //           user,
  //           token,
  //           loggedInWith: "firebase"
  //         }
  //       })
  //       if (user) history.push("/")
  //     })
  //     .catch(error => {
  //       console.log(error)
  //     })
  // }
}

export const loginWithTwitter = () => {
  // return dispatch => {
  //   let provider = new firebase.auth.TwitterAuthProvider()
  //   firebaseAuth
  //     .signInWithPopup(provider)
  //     .then(function(result) {
  //       let token = result.credential.accessToken,
  //         user = result.user.email,
  //         name = result.user.displayName,
  //         photoUrl = result.user.photoURL
  //       dispatch({
  //         type: "LOGIN_WITH_TWITTER",
  //         payload: {
  //           user,
  //           name,
  //           photoUrl,
  //           token,
  //           loggedInWith: "firebase"
  //         }
  //       })
  //       history.push("/")
  //     })
  //     .catch(function(error) {
  //       console.log(error)
  //     })
  // }
}

export const loginWithGoogle = () => {
  // return dispatch => {
  //   let provider = new firebase.auth.GoogleAuthProvider()
  //   firebaseAuth
  //     .signInWithPopup(provider)
  //     .then(function(result) {
  //       console.log(result.user)
  //       let token = result.credential.accessToken,
  //         user = result.user.email,
  //         name = result.user.displayName,
  //         photoUrl = result.user.photoURL
  //       dispatch({
  //         type: "LOGIN_WITH_GOOGLE",
  //         payload: {
  //           email: user,
  //           name: name,
  //           photoUrl,
  //           token,
  //           loggedInWith: "firebase"
  //         }
  //       })
  //       history.push("/")
  //     })
  //     .catch(function(error) {
  //       console.log(error)
  //     })
  // }
}

export const loginWithGithub = () => {
  // return dispatch => {
  //   let provider = new firebase.auth.GithubAuthProvider()
  //   firebaseAuth
  //     .signInWithPopup(provider)
  //     .then(function(result) {
  //       let token = result.credential.accessToken,
  //         user = result.user.email,
  //         name = result.additionalUserInfo.username,
  //         photoUrl = result.user.photoURL

  //       dispatch({
  //         type: "LOGIN_WITH_GITHUB",
  //         payload: {
  //           user,
  //           name,
  //           photoUrl,
  //           token,
  //           loggedInWith: "firebase"
  //         }
  //       })
  //       history.push("/")
  //     })
  //     .catch(function(error) {
  //       console.log(error)
  //     })
  // }
}

export const loginWithJWT = user => {
  return dispatch => {
    axios
      .post(`${API_URL}/auth`, {
        registration: user.registration,
        password: user.password
      })
      .then(response => {
        let loggedInUser = null;
        if (response.data) { // success
          loggedInUser = response.data.user
          localStorage.setItem('userToken', response.data.user.token);
          setSession(loggedInUser);
          dispatch({
            type: "LOGIN_WITH_JWT",
            payload: { loggedInUser, loggedInWith: "jwt" }
          })

          // Change weak password only in production enviroment.
          // if (process.env.REACT_APP_ENVIROMENT !== 'DEV') {
          //   const decimal = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;

          //   if (user.password.match(decimal)) {
          //     history.push("/");
          //   } else {
          //     history.push("/pages/password-change");
          //   }
          // } else {
            history.push("/");
          // }
        }
      })
      .catch(err => {
        let message = 'Erro ao realizar login.';
        if (err.response.data?.message) {
          message = err.response.data.message;
        }
        dispatch({
          type: "LOGIN_ERROR_MESSAGE",
          payload: { errorMessage: message }
        })
      })
  }
}

export const logoutWithJWT = () => {
  let cookies = new Cookies();
  cookies.remove('user');
  localStorage.removeItem('userToken');
  return dispatch => {
    dispatch({ type: "LOGOUT_WITH_JWT", payload: {} })
    history.push("/pages/login")
  }
}

export const logoutWithFirebase = user => {
  return dispatch => {
    dispatch({ type: "LOGOUT_WITH_FIREBASE", payload: {} })
    history.push("/pages/login")
  }
}

export const changeRole = role => {
  return dispatch => dispatch({ type: "CHANGE_ROLE", userRole: role })
}
